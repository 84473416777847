import Main from "./testimonials/Main";
import Slider from "./testimonials/Slider";

export default function Testimonials() {
  return (
    <div className="testimonials-wrapper section">
      <div className="testimonials container">
        <Main />
        <Slider />
      </div>
    </div>
  );
}
