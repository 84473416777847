import { PagesBanner, Contact, Location } from "../components";

export default function ContactPage() {
  return (
    <>
      <PagesBanner
        title="Contact us"
        description="Start the conversation to established good relationship and business."
      />
      <Contact />
      <Location />
    </>
  );
}
