import Card from "./appointment/Card";

import Globe from "../assets/icons/globe.png";
import Settings from "../assets/icons/settings.png";
import Design from "../assets/icons/design.svg";

export default function Appointment() {
  return (
    <div className="appointment-wrapper section">
      <div className="appointment container flex">
        <div className="flex appointment-cards">
          <Card
            src={Globe}
            title="Front-End / Back-End"
            description="Balancing server-side functionality and client-side design for smooth user interactions."
          />
          <Card
            src={Settings}
            title="Support & Maintenance"
            description="Ensuring ongoing performance and addressing issues to keep systems running smoothly."
          />
          <Card
            src={Design}
            title="Design"
            description="Creating visually appealing and user-friendly interfaces that enhance the user experience."
          />
        </div>
      </div>
    </div>
  );
}
