import Content from "./modernbusinesses/Content";
import Stats from "./modernbusinesses/Stats";

export default function ModernBusinesses() {
  return (
    <div className="modernbusinesses-wrapper section">
      <div className="modernbusinesses flex container">
        <Content />
        <Stats />
      </div>
    </div>
  );
}
