import {
  WhoWeAre,
  GreatSection,
  Team,
  PagesBanner,
  GrowthTimeline,
} from "../components";

import Ensar from "../assets/team/ensar.PNG";
import Fatbardha from "../assets/team/fatbardha.jpeg";
import Pellumb from "../assets/team/pellumb.JPG";
import Qendresa from "../assets/team/qendresa.PNG";

const data = [
  {
    pfp: Ensar,
    name: "Ensar Bajrami",
    position: "Founder and CEO",
    linkedin: "https://www.linkedin.com/in/ensar-bajrami-a10a918a/",
  },
  {
    pfp: Fatbardha,
    name: "Fatbardha Dallku",
    position: "Co-Founder and Deputy CEO",
    linkedin: "https://www.linkedin.com/in/fatbardha-dallku-2584a734/",
  },
  {
    pfp: Pellumb,
    name: "Pëllumb Sadiku",
    position: "Programming Teacher",
    linkedin: "https://www.linkedin.com/in/pellumbsadiku/",
  },
  {
    pfp: Qendresa,
    name: "Qëndresa Beqiri",
    position: "Programming Teacher",
    linkedin: "",
  },
];

export default function About() {
  return (
    <>
      <PagesBanner
        title="About us"
        description="Alone we can do so little, together we can do so much."
      />
      <WhoWeAre />
      <GreatSection />
      <GrowthTimeline />
      <Team data={data} more={true} />
    </>
  );
}
