import { Link } from "react-router-dom";

export default function Lower() {
  return (
    <div className="footer-lower flex">
      <div className="footer-copyright">
        <p className="clr-gray-text fs-100 medium">
          Copyright &copy; {new Date().getFullYear()} Innovation Academy, All
          rights reserved.{" "}
        </p>
      </div>
      <div className="footer-legal flex">
        <Link to="/" className="clr-gray-text fs-100 medium">
          Privacy Policy
        </Link>
        <Link to="/" className="clr-gray-text fs-100 medium">
          Legal Notes
        </Link>
        <Link to="/" className="clr-gray-text fs-100 medium">
          Cookie Policy
        </Link>
      </div>
    </div>
  );
}
