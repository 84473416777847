import Logo from "../../assets/brand/logoFull.png";

import LinkedIn from "../../assets/icons/whiteLinkedin.svg";
import Instagram from "../../assets/icons/whiteInstagram.svg";
import Facebook from "../../assets/icons/whiteFacebook.svg";
import TikTok from "../../assets/icons/whiteTiktok.svg";

export default function Main() {
  return (
    <div className="footer-main flex">
      <img className="logo" src={Logo} alt="Innovation Academy Logo" />
      <p className="clr-gray-text">Xhevat Uka, 42000 Vushtrri, Kosovo</p>
      <div className="footer-socials flex">
        <a
          className="flex"
          href="https://www.linkedin.com/company/innovation-academy-ks"
        >
          <img src={LinkedIn} alt="" />
        </a>
        <a
          className="flex"
          href="https://www.instagram.com/innovationacademy_official/"
        >
          <img src={Instagram} alt="" />
        </a>
        <a className="flex" href="https://www.facebook.com/akademiainovative">
          <img src={Facebook} alt="" />
        </a>
        <a className="flex" href="https://www.tiktok.com/@innovation.academy">
          <img src={TikTok} alt="" />
        </a>
      </div>
    </div>
  );
}
