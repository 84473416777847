import Location from "../../assets/icons/location.svg";
import Email from "../../assets/icons/email.svg";
import Phone from "../../assets/icons/phone.svg";

import Card from "./Card";

export default function Cards() {
  return (
    <div className="contact-cards flex">
      <Card
        src={Location}
        title="Head Office"
        description="Xhevat Uka, 42000 Vushtrri, Kosovo"
      />
      <Card
        src={Location}
        title="Albania Office"
        description="Indrit Cara, Kavaje, Albania  +355(69)542-2938"
      />
      <Card
        src={Phone}
        title="Let's Talk"
        description="+383(45)922-250 / +383(45)627-169"
      />
      <Card
        src={Email}
        title="Email Support"
        description="innovationacademy.ks@gmail.com"
      />
    </div>
  );
}
