import { Link } from "react-router-dom";

export default function Links({ title, data }) {
  return (
    <div className="footer-links flex">
      <h3 className="fs-400 clr-white">{title}</h3>
      {data.map((d) => (
        <Link className="fs-200 ff-inter clr-gray-text" to={d.to}>
          {d.page}
        </Link>
      ))}
    </div>
  );
}
