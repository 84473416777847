export default function Main() {
  return (
    <div className="contact-main flex">
      <h4 className="fs-200 medium clr-green uppercase">Get In Touch</h4>
      <h2 className="clr-white fs-700 medium">
        Seamless Communication, <span className="bold">Global Impact</span>.
      </h2>
      <p className="clr-gray-text">
        Reach out to us for any inquiries or support. We're here to assist
        you—connect via email and phone.
      </p>
    </div>
  );
}
