export default function Main() {
  return (
    <div className="testimonials-main">
      <h4 className="fs-200 medium clr-green uppercase">Testimonials</h4>
      <h2 className="clr-white fs-700 medium">
        Client <span className="bold">Feedback</span> & Reviews.
      </h2>
    </div>
  );
}
