import Lower from "./footer/Lower";
import Upper from "./footer/Upper";

export default function Footer() {
  return (
    <div className="footer-wrapper section">
      <div className="footer container">
        <Upper />
        <Lower />
      </div>
    </div>
  );
}
