import { Link } from "react-router-dom";

export default function GreatSection() {
  return (
    <div className="greatsection-wrapper section">
      <div className="greatsection container">
        <div className="greatsection-content flex">
          <h2 className="clr-white fs-700 medium">
            What our students benefit from{" "}
            <span className="bold">learning with us</span>?
          </h2>
          <p className="clr-gray-text">
            Our students gain practical skills in coding, technology,
            communication, and teamwork, preparing them for success in a
            fast-paced world.
          </p>
          <Link
            to="/about"
            className="greenblue-button fs-200 clr-dark transition pointer"
          >
            Learn more
          </Link>
        </div>
      </div>
    </div>
  );
}
