import Banner from "./whoweare/Banner";
// import Clients from "./whoweare/Clients";
import Content from "./whoweare/Content";

export default function WhoWeAre() {
  return (
    <div className="whoweare-wrapper section">
      <div className="whoweare container">
        <div className="whoweare-main flex  ">
          <Banner />
          <Content />
        </div>
        {/* <Clients /> */}
      </div>
    </div>
  );
}
