import Linkedin from "../../assets/icons/linkedIn.svg";

export default function Card({ pfp, name, position, linkedin, isArdi }) {
  return (
    <div className="team-card">
      <div className={`team-card-image ${isArdi && "team-card-image-ardi"}`}>
        <img
          className="pointer transition team-card-pfp"
          src={pfp}
          alt={name}
        />
        <div className="team-card-socials bg-white">
          <a href={linkedin}>
            <img src={Linkedin} alt="Linkedin Icon" />
          </a>
        </div>
      </div>
      <div className="team-card-content">
        <h3 className="clr-white fs-400 ">{name}</h3>
        <h4 className="clr-blue fs-200">{position}</h4>
      </div>
    </div>
  );
}
