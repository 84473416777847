export default function Form() {
  return (
    <div className="contact-form flex">
      <h2 className="fs-700 clr-white medium ">Send us a message</h2>
      <p className="clr-gray-text">
        Reach out to us for any inquiries or support. We're here to assist
        you—connect via email and phone.
      </p>
      <form action="">
        <div className="inputs-holder ">
          <div className="contact-input-container flex">
            <label className="clr-white medium" htmlFor="name">
              Name*
            </label>
            <input
              className="clr-gray"
              type="text"
              name="name"
              id="name"
              placeholder="Name"
              required
            />
          </div>
          <div className="contact-input-container flex">
            <label className="clr-white medium" htmlFor="surname">
              Surname*
            </label>
            <input
              className="clr-gray"
              type="text"
              name="surname"
              id="surname"
              placeholder="Surname"
              required
            />
          </div>
        </div>
        <div className="inputs-holder">
          <div className="contact-input-container flex">
            <label className="clr-white medium" htmlFor="phone">
              Phone*
            </label>
            <input
              className="clr-gray"
              type="number"
              name="phone"
              id="phone"
              placeholder="Phone"
              required
            />
          </div>
          <div className="contact-input-container flex">
            <label className="clr-white medium" htmlFor="email">
              Email*
            </label>
            <input
              className="clr-gray"
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              required
            />
          </div>
        </div>
        <div className="contact-input-container flex">
          <label className="clr-white medium" htmlFor="phone">
            Subject*
          </label>
          <input
            className="clr-gray"
            type="text"
            name="subject"
            id="subject"
            placeholder="Subject"
            required
          />
        </div>
        <div className="contact-input-container flex">
          <label className="clr-white medium" htmlFor="message">
            Message*
          </label>
          <textarea
            name="message"
            id="message"
            placeholder="Message"
          ></textarea>
        </div>
        <input
          className="greenblue-button fs-200 clr-dark transition pointer"
          type="submit"
          value="Send message"
        />
      </form>
    </div>
  );
}
