import Card from "./Card";

export default function Grid({ data }) {
  return (
    <div className="team-grid">
      {data.map((t) => (
        <Card
          pfp={t.pfp}
          name={t.name}
          position={t.position}
          linkedin={t.linkedin}
          isArdi={t.isArdi}
        />
      ))}
    </div>
  );
}
