import { Link } from "react-router-dom";

import Icon from "../../assets/brand/logoFull.png";

export default function Logo() {
  return (
    <div className="header-logo">
      <Link to="/" className="flex">
        <img src={Icon} alt="Innovation Academy Logo" />
      </Link>
    </div>
  );
}
