import Title from "./banner/Title";
import Description from "./banner/Description";
import Buttons from "./banner/Buttons";

export default function Banner() {
  return (
    <div className="banner-wrapper section">
      <div className="banner flex">
        <Title />
        <Description />
        <Buttons />
      </div>
    </div>
  );
}
