import { PagesBanner, Projects } from "../components";

export default function ProjectsPage() {
  return (
    <>
      <PagesBanner
        title="Projects"
        description="We love sharing our students projects with the world."
      />
      <Projects />
    </>
  );
}
