export default function Card({ src, title, description }) {
  return (
    <div className="contact-card flex">
      <div className="contact-card-icon flex">
        <img width={28} src={src} alt={title} />
      </div>
      <div>
        <h4 className="clr-white fs-400 ">{title}</h4>
        <p className="clr-gray-text ">{description}</p>
      </div>
    </div>
  );
}
