import { Link } from "react-router-dom";

export default function Content() {
  return (
    <div className="modernbusinesses-content flex">
      <h2 className="clr-white fs-700 medium">
        Join the most <span className="bold">Innovative Community</span> of the
        country.
      </h2>
      <p className="clr-gray-text">
        Our innovative community fosters creativity, collaboration, and
        continuous learning, empowering individuals to share ideas, solve
        problems, and drive positive change together.
      </p>
      <Link
        to="/about"
        className="greenblue-button fs-200 clr-dark transition pointer"
      >
        Learn more
      </Link>
    </div>
  );
}
