export default function PagesBanner({ title, description }) {
  return (
    <div className="pagesbanner-wrapper">
      <div className="pagesbanner container section">
        <h1 className="fs-900 medium">{title}</h1>
        <p className="clr-white fs-500 thin">{description}</p>
      </div>
    </div>
  );
}
