import React from "react";

import Eappartament from "../assets/images/eapartament.png";
import MarvelHub from "../assets/images/marvelhub.png";
import Interno from "../assets/images/interno.png";
import Forkify from "../assets/images/forkify.png";
import Ardbraha from "../assets/images/ardbraha.png";
import VushtrriaBasket from "../assets/images/vushtrriabasket.png";
import Farm from "../assets/images/zoufarmm.png";

const projectsData = [
  {
    title: "E-Apartament",
    description:
      "E-Aparament is a project created by our students where you can find and book apartaments online.",
    image: Eappartament,
    tags: ["HTML", "CSS", "JavaScript"],
    link: "https://e-apartament.netlify.app/",
  },
  {
    title: "MarvelHub",
    description:
      "Marvelhub is a project created by our students where you can find everything you need  to know about Marvel.",
    image: MarvelHub,
    tags: ["HTML", "CSS", "JavaScript"],
    link: "https://marvelhub.netlify.app/",
  },
  {
    title: "Interno",
    description:
      "Interno is a beatuful furnitures website where you can browse and create your interior.",
    image: Interno,
    tags: ["HTML", "CSS"],
    link: "https://ardbraha-interno.netlify.app/",
  },
  {
    title: "Forkify",
    description:
      "Forkify is a web where you can browse and create recipes so that you next cook will be easier.",
    image: Forkify,
    tags: ["HTML", "CSS", "JavaScript"],
    link: "https://ardbraha-forkify.netlify.app/",
  },
  // {
  //   title: "Ard Braha's Portfolio",
  //   description:
  //     "Ardi is our student and colleague, with his awesome work he managed to create his personal portfolio.",
  //   image: Ardbraha,
  //   tags: ["React.JS", "SASS"],
  //   link: "https://www.ardbraha.com/",
  // },
  {
    title: "Vushtrria Basket",
    description:
      "Our student created a website for our city basketball team where he showed his love for basket and coding.",
    image: VushtrriaBasket,
    tags: ["HTML", "CSS", "JavaScript"],
    link: "https://vushtrriabasket.netlify.app/",
  },
  {
    title: "Zou Farm",
    description:
      "Zou Farm is a website created by love from our students to a lovely farm.",
    image: Farm,
    tags: ["HTML", "CSS"],
    link: "https://zoufarmm.netlify.app/home",
  },
];

export default function Projects() {
  return (
    <section className="projects">
      <div className="container">
        <div className="projects__grid">
          {projectsData.map((project, index) => (
            <div key={index} className="project-card">
              <div className="project-card__image">
                <img src={project.image} alt={project.title} />
                <div className="project-card__overlay">
                  <div className="project-card__tags">
                    {project.tags.map((tag, tagIndex) => (
                      <span key={tagIndex} className="project-card__tag">
                        {tag}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <div className="project-card__content">
                <h3 className="project-card__title">{project.title}</h3>
                <p className="project-card__description">
                  {project.description}
                </p>
                <a href={project.link} className="project-card__link">
                  View Project
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 12H19M19 12L12 5M19 12L12 19"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
