export default function Location() {
  return (
    <div className="location-wrapper">
      <div className="location">
        <iframe
          title="Innovation Academy Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2926.2738978014113!2d20.9618926!3d42.824827799999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13535bf904a0b329%3A0x9c767fc069643180!2sInnovation%20Academy!5e0!3m2!1sen!2s!4v1740672560896!5m2!1sen!2s"
          width="600"
          height="450"
          style={{
            width: "100%",
            border: 0,
            filter: "grayscale(100%) ",
          }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}
