import Card from "./Card";

const data = [
  {
    percentage: "70%",
    subtitle: "Student Increase",
    description:
      "The quantity of students continues to grow as we provide valuable skills.",
  },
  {
    percentage: "60%",
    subtitle: "Staff Increase",
    description:
      "The increase in staff reflects our commitment to providing high-quality education.",
  },
];

export default function Stats() {
  return (
    <div className="modernbusinesses-stats flex">
      {data.map((d) => (
        <Card
          percentage={d.percentage}
          subtitle={d.subtitle}
          descripton={d.description}
        />
      ))}
    </div>
  );
}
