import { Link } from "react-router-dom";

export default function NavLink({ to, children, active }) {
  return (
    <Link
      to={to}
      className={`header-nav-link transition pointer clr-white ${
        active && "active"
      }`}
    >
      {children}
    </Link>
  );
}
