import ArrowUp from "../../assets/icons/arrowUp.svg";

export default function Card({ percentage, subtitle, descripton }) {
  return (
    <div className="modernbusinesses-card">
      <div>
        <div className="main flex">
          <h1 className="clr-white fs-900 medium">+{percentage}</h1>
          <img src={ArrowUp} alt="Arrow Up Icon" />
        </div>
        <h4 className="clr-gray-text fs-400">{subtitle}</h4>
      </div>
      <p className="clr-gray-text">{descripton}</p>
    </div>
  );
}
