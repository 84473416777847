import Ard from "../../assets/text.jpg";

export default function Card({ testimonial, index, writer, position }) {
  return (
    <div key={index} className="testimonials-card">
      <p className="clr-gray-text">{testimonial}</p>
      <div className="testimonials-card-writer flex">
        <img src={Ard} alt="Ard Braha" />
        <div>
          <h3 className="clr-white medium">{writer}</h3>
          <h4 className="clr-gray-text medium ff-inter fs-100">{position}</h4>
        </div>
      </div>
    </div>
  );
}
