import { PagesBanner, Team } from "../components";

import Ard from "../assets/team/ardbraha.jpg";
import Ensar from "../assets/team/ensar.PNG";
import Fatbardha from "../assets/team/fatbardha.jpeg";
import Pellumb from "../assets/team/pellumb.JPG";
import Qendresa from "../assets/team/qendresa.PNG";
import Korab from "../assets/team/korabi.jpeg";
import Argjenda from "../assets/team/argjenda.JPG";
import Lorik from "../assets/team/lorikrama.JPG";
import Ardita from "../assets/team/ardita.JPG";
import Florenta from "../assets/team/florenta.jpeg";
import Valbona from "../assets/team/valbona.JPG";
import Drenusha from "../assets/team/drenusha.jpeg";
import Agron from "../assets/team/agron.jpeg";
import Hamide from "../assets/team/hamide.png";
import Suela from "../assets/team/suela.jpeg";
import Njomza from "../assets/team/njomza.png";
import SuelaH from "../assets/team/suelah.jpeg";
import Erxhan from "../assets/team/erxhan.jpg";
import Ambera from "../assets/team/ambera.jpeg";
import ExecutiveDirector from "../assets/team/ExecutiveDirector.jpg";

const data = [
  {
    pfp: Ensar,
    name: "Ensar Bajrami",
    position: "Founder and CEO",
    linkedin: "https://www.linkedin.com/in/ensar-bajrami-a10a918a/",
  },
  {
    pfp: Fatbardha,
    name: "Fatbardha Dallku",
    position: "Co-Founder and Deputy CEO",
    linkedin: "https://www.linkedin.com/in/fatbardha-dallku-2584a734/",
  },
  {
    pfp: ExecutiveDirector,
    name: "Valbona Karkini Mustafa",
    position: "Executive Director in Kavaja, Albania",
    linkedin: "#",
  },
  {
    pfp: Pellumb,
    name: "Pëllumb Sadiku",
    position: "Programming Teacher",
    linkedin: "https://www.linkedin.com/in/pellumbsadiku/",
  },
  {
    pfp: Qendresa,
    name: "Qëndresa Beqiri",
    position: "Programming Teacher",
    linkedin: "",
  },
  {
    pfp: Korab,
    name: "Korab Duklini",
    position: "Programming Teacher",
    linkedin: "https://www.linkedin.com/in/korab-duklini/",
  },
  {
    pfp: Agron,
    name: "Agron Ahmeti",
    position: "Programming Teacher",
    linkedin: "",
  },
  {
    pfp: Argjenda,
    name: "Argjendë Zabërgja",
    position: "Programming Teacher",
    linkedin:
      "https://www.linkedin.com/in/argjend%C3%AB-zab%C3%ABrgja-746528238/",
  },
  {
    pfp: Ard,
    name: "Ard Braha",
    position: "Programming Teacher",
    linkedin: "https://www.linkedin.com/in/ardbraha/",
    isArdi: true,
  },
  {
    pfp: Lorik,
    name: "Lorik Rama",
    position: "Programming Teacher Assistant",
    linkedin: "https://www.linkedin.com/in/lorikramaa/",
  },
  {
    pfp: Suela,
    name: "Suela Selimi",
    position: "Programming Teacher Assistant",
    linkedin: "https://www.linkedin.com/in/suela-selimi/",
  },
  {
    pfp: Drenusha,
    name: "Drenushë Behrami",
    position: "Social Media Manager",
    linkedin: "https://www.linkedin.com/in/drenusha-behrami-85b496251/",
  },
  {
    pfp: SuelaH,
    name: "Suela Hasani",
    position: "Social Media Manager",
    linkedin: "https://www.linkedin.com/in/suela-hasani-1258481b4/",
  },
  {
    pfp: Erxhan,
    name: "Erxhan Llapashtica",
    position: "Designer",
    linkedin: "#",
  },
  {
    pfp: Njomza,
    name: "Njomza Badivuku",
    position: "English Teacher",
    linkedin: "https://www.linkedin.com/in/njomza-badivuku-970260326/",
  },
  {
    pfp: Ardita,
    name: "Ardita Halili",
    position: "English Teacher",
    linkedin: "",
  },
  {
    pfp: Hamide,
    name: "Hamide Januzi",
    position: "English Teacher",
    linkedin: "https://www.linkedin.com/in/hamide-januzi-453bba1a8/",
  },
  {
    pfp: Florenta,
    name: "Florenta Mulaku",
    position: "Additional Learning Teacher",
    linkedin: "https://www.linkedin.com/in/florenta-mulaku-0a43bb19b/",
  },
  {
    pfp: Valbona,
    name: "Valbona Krasniqi",
    position: "Administrative Assistant",
    linkedin: "",
  },
  {
    pfp: Ambera,
    name: "Ambera Peci",
    position: "Administrative Assistant",
    linkedin: "",
  },
];

export default function TeamPage() {
  return (
    <>
      <PagesBanner
        title="Team"
        description="Alone we can do so little, together we can do so much."
      />
      <Team data={data} />
    </>
  );
}
