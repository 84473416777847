import Links from "./Links";
import Main from "./Main";

export default function Upper() {
  return (
    <div className="footer-upper flex">
      <Main />
      <div className="footer-links-container flex">
        <Links
          title="Company"
          data={[
            {
              to: "/",
              page: "Homepage",
            },
            {
              to: "/about",
              page: "About Us",
            },
            {
              to: "/programs",
              page: "Programs",
            },
            {
              to: "/projects",
              page: "Projects",
            },
            {
              to: "/team",
              page: "Team",
            },
            {
              to: "/contact",
              page: "Contact",
            },
          ]}
        />
        <Links
          title="Programs"
          data={[
            {
              to: "#",
              page: "Programming",
            },
            {
              to: "#",
              page: "English Learning",
            },
            {
              to: "#",
              page: "Extra Lessons",
            },
            {
              to: "#",
              page: "Robotics",
            },
            {
              to: "#",
              page: "Design",
            },
            {
              to: "#",
              page: "Summer Camps",
            },
          ]}
        />
        <Links
          title="Stats"
          data={[
            {
              to: "#",
              page: "1000+ Students",
            },
            {
              to: "#",
              page: "10+ Awards",
            },
            {
              to: "#",
              page: "3 Locations",
            },
            {
              to: "#",
              page: "5+ Years",
            },
            {
              to: "#",
              page: "20+ Staff",
            },
            {
              to: "#",
              page: "6+ Classes",
            },
          ]}
        />
      </div>
    </div>
  );
}
