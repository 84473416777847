import Info from "./contact/Info";
import Form from "./contact/Form";

export default function Contact() {
  return (
    <div className="contact-wrapper section">
      <div className="contact container flex">
        <Info />
        <Form />
      </div>
    </div>
  );
}
