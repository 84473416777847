import { Link } from "react-router-dom";
import Stats from "./Stats";

export default function Content() {
  return (
    <div className="whoweare-content flex">
      <h4 className="fs-200 medium clr-green uppercase">Who We Are</h4>
      <h2 className="clr-white fs-700 medium">
        Innovative Solutions for Your{" "}
        <span className="bold">Online Success</span>.
      </h2>
      <p className="clr-gray-text">
        Founded in 2019 in Vushtrri, Kosovo, Innovation Academy was established
        by Ensar Bajrami and Fatbardha Dallku as the first of its kind. The
        academy aims to empower Kosovo's youth with modern programs to bridge
        gaps left by public schools, offering skills in coding, communication,
        problem-solving, critical thinking, and teamwork. Students can enhance
        their learning after school with these programs.
      </p>
      <Stats />
      <Link
        to="/about"
        className="greenblue-button fs-200 clr-dark transition pointer"
      >
        Discover more
      </Link>
    </div>
  );
}
