import Cards from "./Cards";
import Main from "./Main";

export default function Info() {
  return (
    <div className="contact-info">
      <Main />
      <Cards />
    </div>
  );
}
