import CorrectIcon from "../../assets/icons/correctcircle.svg";

export default function List({ data }) {
  return (
    <div className="whoweare-list">
      <ul>
        {data.map((data) => (
          <li className="flex clr-white medium">
            <img src={CorrectIcon} alt="Correct Icon" />
            {data}
          </li>
        ))}
      </ul>
    </div>
  );
}
