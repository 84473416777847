import {
  Banner,
  Appointment,
  WhoWeAre,
  GreatSection,
  ModernBusinesses,
  Testimonials,
} from "../components";

export default function Home() {
  return (
    <>
      <Banner />
      <Appointment />
      <WhoWeAre />
      <GreatSection />
      <ModernBusinesses />
      <Testimonials />
    </>
  );
}
