import List from "./List";
import BannerImage from "../../assets/images/robot.png";

const data = [
  "Learning through practice",
  "Critical Thinking",
  "Unique Creativity",
  "Responsive Design",
  "Innovative Technology",
  "Motivation",
];

export default function Banner() {
  return (
    <div className="whoweare-banner">
      <img
        className="transition pointer whoweare-banner-image"
        src={BannerImage}
        alt="Banner"
      />
      <List data={data} />
    </div>
  );
}
