export default function Content() {
  return (
    <div className="team-content">
      <h4 className="fs-200 medium clr-green uppercase">Who We Are</h4>
      <h2 className="clr-white fs-700 medium">
        We talk a lot about <span className="bold">hope</span> helping and{" "}
        <span className="bold">teamwork</span>.
      </h2>
    </div>
  );
}
