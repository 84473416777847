export default function Stats() {
  return (
    <div className="stats-holder flex">
      <div>
        <h1 className="fs-800 clr-white medium">1K+</h1>
        <h3 className="clr-gray-text fs-400">Students</h3>
      </div>
      <div>
        <h1 className="fs-800 clr-white medium">20+</h1>
        <h3 className="clr-gray-text fs-400">Staff</h3>
      </div>
      <div>
        <h1 className="fs-800 clr-white medium">100+</h1>
        <h3 className="clr-gray-text fs-400">Projects</h3>
      </div>
    </div>
  );
}
