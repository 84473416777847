import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Logo from "./header/Logo";
import Nav from "./header/Nav";
import Button from "./header/Button";

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location.pathname]);

  return (
    <div className="header-wrapper">
      <div className="header flex container">
        <Logo />
        <button className="mobile-menu-toggle" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div className={`header-content ${isMenuOpen ? "active" : ""}`}>
          <Nav />
          <Button />
        </div>
      </div>
    </div>
  );
}
