import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Card from "./Card";

const responsive = {
  desktop: {
    breakpoint: { max: 5000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1100, min: 600 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 600, min: 200 },
    items: 1,
  },
};

const Slider = () => {
  const testimonials = [
    {
      writer: "Parent",
      profilePicture: "",
      role: "Parent of a Student",
      description:
        "Innovation Academy has transformed my son's learning experience. He used to struggle with traditional subjects, but now he's coding his own projects with confidence. We couldn't be happier with his progress!",
    },
    {
      writer: "Parent",
      profilePicture: "",
      role: "Parent of a Student",
      description:
        "Our daughter has always been passionate about technology, and Innovation Academy gave her the perfect environment to thrive. She recently built her first mobile app, and we are beyond proud!",
    },
    {
      writer: "Parent",
      profilePicture: "",
      role: "Parent of a Student",
      description:
        "This school is a gateway to the future. My son is learning real-world skills like coding, robotics, and problem-solving. He comes home excited every day to share what he's learned! They're just awesome!",
    },
    {
      writer: "Parent",
      profilePicture: "",
      role: "Parent of a Student",
      description:
        "Unlike traditional schools, Innovation Academy focuses on practical knowledge. My daughter participated in a tech competition and won first place, thanks to the skills she learned here! I really suggest Innovation Academy!",
    },
  ];

  return (
    <Carousel
      responsive={responsive}
      swipeable={true}
      draggable={true}
      showDots={false}
      dotListClass="custom-dot-list-style"
    >
      {testimonials.map((testimonial, index) => {
        return (
          <Card
            key={index}
            testimonial={testimonial.description}
            index={index}
            writer={testimonial.writer}
            position={testimonial.role}
          />
        );
      })}
    </Carousel>
  );
};
export default Slider;
