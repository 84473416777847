import { PagesBanner, Programs } from "../components";

export default function ProgramsPage() {
  return (
    <>
      <PagesBanner
        title="Our Programs"
        description="Discover our comprehensive learning programs designed to help you succeed in the tech industry."
      />
      <Programs />
    </>
  );
}
