import React from "react";

const timelineData = [
  {
    year: "2021",
    title: "First Location",
    description:
      "Opening our first location in Vushtrri, Kosovo, marking the beginning of our journey in tech education.",
    location: "Vushtrri, Kosovo",
  },
  {
    year: "2025",
    title: "Second Location",
    description:
      "Expanding to our second location in Kavajë, Albania, bringing tech education to more students.",
    location: "Kavajë, Albania",
  },
  {
    year: "2025",
    title: "International Expansion",
    description:
      "Opening our first international office in Qatar, taking our innovative education model global.",
    location: "Qatar",
  },
];

export default function GrowthTimeline() {
  return (
    <section className="growth-timeline">
      <div className="container">
        <div className="growth-timeline__header">
          <h2>Our Growth Journey</h2>
          <p>
            From a single location to international expansion, our journey
            continues to evolve.
          </p>
        </div>
        <div className="growth-timeline__content">
          {timelineData.map((item, index) => (
            <div key={index} className="timeline-item">
              <div className="timeline-item__line">
                <div className="timeline-item__dot"></div>
                {index !== timelineData.length - 1 && (
                  <div className="timeline-item__connector"></div>
                )}
              </div>
              <div className="timeline-item__content">
                <div className="timeline-item__year">{item.year}</div>
                <h3 className="timeline-item__title">{item.title}</h3>
                <p className="timeline-item__description">{item.description}</p>
                <div className="timeline-item__location">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 1.33334C6.15094 1.33334 4.5 2.98428 4.5 4.83334C4.5 7.33334 8 14.6667 8 14.6667C8 14.6667 11.5 7.33334 11.5 4.83334C11.5 2.98428 9.84906 1.33334 8 1.33334ZM8 6.16667C7.54167 6.16667 7.16667 5.79167 7.16667 5.33334C7.16667 4.875 7.54167 4.5 8 4.5C8.45833 4.5 8.83333 4.875 8.83333 5.33334C8.83333 5.79167 8.45833 6.16667 8 6.16667Z"
                      fill="currentColor"
                    />
                  </svg>
                  {item.location}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
