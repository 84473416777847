import { Link } from "react-router-dom";

export default function Button() {
  return (
    <div className="banner-buttons flex">
      <Link className="banner-button clr-dark pointer transition" to="/contact">
        About Innovation Academy
      </Link>
      <Link
        className="banner-button banner-button-white clr-white pointer transition"
        to="/contact"
      >
        Our Beloved Team
      </Link>
    </div>
  );
}
