import { Link } from "react-router-dom";
import Content from "./team/Content";
import Grid from "./team/Grid";

export default function Team({ data, more }) {
  return (
    <div className="team-wrapper section">
      <div className="team flex container">
        <Content />
        <Grid data={data} />
        {more && (
          <div>
            <Link
              className="greenblue-button clr-dark transition pointer"
              to="/team"
            >
              View Full Team
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
