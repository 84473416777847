import { useLocation } from "react-router-dom";

import NavLink from "./NavLink";

export default function Nav() {
  const location = useLocation();
  const path = location.pathname;

  return (
    <ul className="header-nav">
      <NavLink active={path === "/"} to="/">
        Homepage
      </NavLink>
      <NavLink active={path === "/about"} to="/about">
        About us
      </NavLink>
      <NavLink active={path === "/programs"} to="/programs">
        Programs
      </NavLink>
      <NavLink active={path === "/projects"} to="/projects">
        Projects
      </NavLink>
      <NavLink active={path === "/team"} to="/team">
        Team
      </NavLink>
    </ul>
  );
}
