import React from "react";

const programsData = [
  {
    title: "Programming",
    description:
      "Learn web development with React, Node.js, and full-stack technologies, preparing for modern tech challenges.",
    icon: "💻",
    duration: "6 months",
    level: "Beginner",
  },
  {
    title: "English Learning",
    description:
      "Improve your English language skills for better communication, writing, and professional success.",
    icon: "📚",
    duration: "6 months",
    level: "Beginner",
  },
  {
    title: "Logic Mathematics",
    description:
      "Explore data analysis, machine learning, and AI concepts to develop strong problem-solving skills.",
    icon: "📊",
    duration: "8 months",
    level: "Beginner",
  },
  {
    title: "Extra Lessons",
    description:
      "Enhance your creativity by learning design principles for creating user-friendly and engaging interfaces.",
    icon: "🎨",
    duration: "4 months",
    level: "Beginner",
  },
  {
    title: "Robotics",
    description:
      "Dive into robotics with coding and mechanical design to build practical projects and real-world solutions.",
    icon: "🤖",
    duration: "6 months",
    level: "Beginner",
  },
  {
    title: "Design",
    description:
      "Master UI/UX design and create intuitive mobile apps for both iOS and Android platforms.",
    icon: "🖌️",
    duration: "6 months",
    level: "Beginner",
  },
  {
    title: "Digital Marketing",
    description:
      "Learn digital marketing strategies like SEO, social media, and data analytics to optimize online campaigns.",
    icon: "📈",
    duration: "8 months",
    level: "Advanced",
  },
  {
    title: "Summer Camps",
    description:
      "Join our summer camps to learn new skills, engage in hands-on projects, and enjoy fun educational activities.",
    icon: "☀️",
    duration: "4 months",
    level: "Beginner",
  },
  {
    title: "Web Development",
    description:
      "Learn the fundamentals of web development with technologies like React and build modern applications.",
    icon: "🌐",
    duration: "6 months",
    level: "Beginner",
  },
  {
    title: "Training",
    description:
      "Build mobile apps for iOS and Android, learning the tools and skills needed for app development success.",
    icon: "📱",
    duration: "6 months",
    level: "Beginner",
  },
  {
    title: "React",
    description:
      "Build fast, interactive UIs with React by creating components that ensure updates and smooth performance.",
    icon: "⚛️",
    duration: "6 months",
    level: "Beginner",
  },
  {
    title: "WordPress",
    description:
      "Easily manage content and build dynamic websites with WordPress, offering flexibility through themes and plugins.",
    icon: "🌐",
    duration: "6 months",
    level: "Beginner",
  },
];

export default function Programs() {
  return (
    <section className="programs">
      <div className="container">
        <div className="programs__grid">
          {programsData.map((program, index) => (
            <div key={index} className="program-card">
              <div className="program-card__icon">{program.icon}</div>
              <h3 className="program-card__title">{program.title}</h3>
              <p className="program-card__description">{program.description}</p>
              <div className="program-card__details">
                <span className="program-card__duration">
                  {program.duration}
                </span>
                <span className="program-card__level">{program.level}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
